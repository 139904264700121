<div id="wrapper" class="container">
  <div class="row justify-content-md-center">
    <div class=" col-md-6">
      <h1>Animals</h1>
      <form>
        <fieldset>
          <legend>
            <span class="number">
              <i class="far fa-futbol fa-log"></i>
            </span>
            <span class="font-weight-bold">
              Filter by name:
            </span>
          </legend>
          <br>
          <input type="text" name="search" placeholder="Search..." [(ngModel)]="text" autocomplete="off">
        </fieldset>

        <ul class="animal-list" *ngIf="(Animals | filter: text).length > 0; else noResults">
          <li *ngFor="let animal of Animals | filter : text">
            {{animal}}
          </li>
        </ul>

        <ng-template #noResults>
          <div class="no-results">No results found for "{{text}}".</div>
        </ng-template>
      </form>
    </div>

  </div>
  <app-fb-credit></app-fb-credit>
</div>
