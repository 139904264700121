<footer class="fb-credit" [class.dark]="darkMode">
  <a href="https://www.futurebutterflies.com" target="_blank" class="link-wrapper" [ngClass]="{active : hovered}"
    (mouseover)="hovered=true">Created by
    <br>
    <svg version="1.1" class="logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 200 29.4" style="enable-background:new 0 0 200 29.4;" xml:space="preserve">
      <g>
        <path class="st0" d="M15.2,8.3h-3C12.6,6.6,13,5,13.5,3.4c0.2-0.6,0.6-1.6,1.5-1.6c1.2,0,0.8,2.1,2.6,2.1c0.8,0,1.4-0.7,1.4-1.5
		c0-1.5-1.8-1.9-3-1.9c-4.4,0-6.2,4.3-7.1,7.8H6.3L6,9.5h2.6L5.9,21.3c-0.4,1.8-0.9,3.6-1.3,5.4c-0.1,0.5-0.3,1.5-1.1,1.5
		c0,0,0,0,0,0c-0.4-0.1-0.7-0.6-0.6-1c0,0,0,0,0,0c0-0.7-0.6-1.3-1.3-1.4c-0.8,0-1.4,0.6-1.5,1.4c0,1.5,1.4,2.1,2.7,2.1
		c3.5,0,5-3.1,5.9-6L12,9.5h2.9L15.2,8.3z" />
        <path class="st0" d="M26.4,19.5c-0.5,0-0.6-0.6-0.6-1c0.2-1.7,0.5-3.3,1-4.9L28.7,8h-3.3l-0.9,2.8c-0.7,2-2.7,8.6-5.3,8.6
		c-0.1,0-0.1,0-0.2,0c-0.4,0-0.7-0.4-0.7-0.8c0.2-1.1,0.5-2.1,0.9-3.1l0.7-2.2c0.4-1.1,0.7-2.3,0.8-3.6c0,0,0-0.1,0-0.1
		c-0.1-1.1-1-2-2.1-1.9c-2.4,0-3.8,2.2-4.8,4.1l0.5,0.3c0.5-0.7,1.4-2.6,2.3-2.6c0,0,0,0,0.1,0c0.3,0,0.5,0.3,0.5,0.6
		c-0.1,0.6-0.2,1.2-0.5,1.8l-1,3.3c-0.4,1.2-0.7,2.4-0.9,3.7c0,0.1,0,0.2,0,0.2c0.1,1.3,1.2,2.3,2.5,2.2c2.5,0,4.4-2.5,5.4-4.6
		l0.1,0.1c-0.2,0.7-0.3,1.5-0.3,2.3c0,0,0,0.1,0,0.1c0,1.1,0.9,2.1,2,2.2c2.4,0,3.8-2.6,4.6-4.5l-0.6-0.2
		C28.2,17.3,27.3,19.5,26.4,19.5z" />
        <path class="st0" d="M37.2,17c-0.4,0.7-1.5,2.6-2.5,2.6c0,0,0,0,0,0c-0.5-0.1-0.8-0.6-0.7-1.1c0.1-1.2,0.4-2.4,0.8-3.6l1.4-5.3h2.9
		l0.3-1.2h-2.9l1.3-4.8h-3.1L33,8.4h-2.5l-0.3,1.2h2.4l-1.3,4.8c-0.5,1.5-0.8,3.1-0.9,4.6c0,0,0,0.1,0,0.1c0,1.3,1.2,2.4,2.5,2.3
		c2.4,0,4-2.7,5-4.6l-0.6-0.3L37.2,17z" />
        <path class="st0" d="M51.1,19.5c-0.5,0-0.6-0.6-0.6-1c0.2-1.7,0.5-3.3,1-4.9L53.2,8H50L49,10.8c-0.7,2-2.7,8.6-5.3,8.6
		c-0.1,0-0.1,0-0.2,0c-0.4,0-0.7-0.4-0.7-0.8c0.2-1.1,0.5-2.1,0.9-3.1l0.7-2.2c0.4-1.1,0.7-2.3,0.8-3.6c0,0,0-0.1,0-0.1
		c-0.1-1.1-1-2-2.1-1.9c-2.4,0-3.8,2.2-4.8,4.1l0.6,0.3c0.5-0.7,1.4-2.6,2.3-2.6c0,0,0.1,0,0.1,0c0.3,0.1,0.5,0.3,0.4,0.6
		c-0.1,0.6-0.2,1.2-0.5,1.8l-1,3.3c-0.4,1.2-0.7,2.4-0.9,3.7c0,0.1,0,0.2,0,0.2c0.1,1.3,1.2,2.3,2.5,2.2c2.5,0,4.4-2.5,5.4-4.6
		l0.1,0.1c-0.2,0.7-0.3,1.5-0.3,2.3c0,0,0,0.1,0,0.1c0,1.1,0.9,2.1,2,2.2c2.4,0,3.8-2.6,4.6-4.5l-0.6-0.2
		C52.9,17.3,52,19.5,51.1,19.5z" />
        <path class="st0" d="M67.1,9.8c0-0.1,0-0.2,0-0.3C67,8.4,66,7.6,64.9,7.7c-2.4,0-3.7,2.9-4.6,4.8l-0.1-0.1c0.1-0.8,0.2-1.7,0.3-2.6
		c0-0.1,0-0.1,0-0.2c0-1.1-0.8-1.9-1.9-1.9c-2.4,0-3.8,2.6-4.7,4.5l0.7,0.3c0.3-0.7,1.1-2.7,2-2.7c0.4,0,0.5,0.4,0.5,0.8
		c-0.2,2.2-0.6,4.4-1.3,6.6L54.6,21h3.5l1.2-3.3c0.4-1.4,2.3-7.4,4-7.4c0.7,0,0.7,1.3,2,1.3c0.1,0,0.1,0,0.2,0
		C66.4,11.6,67.2,10.8,67.1,9.8z" />
        <path class="st0" d="M74.4,7.7c-4.8,0-8.2,4.5-8.2,9.1c0,2.8,1.6,4.6,4.4,4.6c2.6,0,4.6-1.9,6-4L76,16.8c-1,1.2-2.4,2.9-4.1,2.9
		c-0.1,0-0.1,0-0.2,0c-1.1-0.1-1.9-1.2-1.8-2.3c0-0.6,0.1-1.2,0.2-1.8c2.7-0.7,8.1-1.9,8.1-5.3C78.2,8.4,76,7.7,74.4,7.7z
		 M70.3,14.7c0.4-1.7,1.6-6,3.8-6c0,0,0.1,0,0.1,0c0.6,0.1,1.1,0.6,1,1.3C75.2,12.4,72.3,14.1,70.3,14.7z" />
        <path class="st0" d="M88,7.7c-1.5,0-2.8,0.7-3.7,1.8l-0.1-0.1l2.6-9.1l-6.5,0.4l-0.2,0.9c0.8,0,2.3,0,2.3,1.1
		c-0.2,1.3-0.5,2.6-0.9,3.9l-1.6,6c-0.5,1.6-0.8,3.2-0.9,4.8c0,2.5,1.8,3.9,4.2,3.9c5,0,8.5-4.9,8.5-9.6C91.7,9.6,90.5,7.7,88,7.7z
		 M83.5,20.4c-0.1,0-0.1,0-0.2,0c-0.8-0.1-1.4-0.9-1.3-1.7v0c0-2,1.7-9.1,4.5-9.2c1.2-0.1,1.6,0.9,1.6,1.9
		C88.2,13.5,86.4,20.4,83.5,20.4z" />
        <path class="st0" d="M105.7,19.5c-0.5,0-0.6-0.6-0.6-1c0.2-1.7,0.5-3.3,1-4.9l1.7-5.6h-3.3l-0.9,2.8c-0.6,2-2.7,8.6-5.3,8.6
		c-0.1,0-0.1,0-0.2,0c-0.4,0-0.7-0.4-0.7-0.8c0.2-1.1,0.5-2.1,0.9-3.1l0.8-2.2c0.4-1.1,0.7-2.3,0.8-3.6c0,0,0-0.1,0-0.1
		c-0.1-1.1-1-2-2.1-1.9c-2.4,0-3.8,2.2-4.8,4.1l0.6,0.3c0.5-0.7,1.4-2.6,2.3-2.6c0,0,0.1,0,0.1,0c0.3,0.1,0.5,0.3,0.4,0.6
		c-0.1,0.6-0.2,1.2-0.5,1.8l-1,3.3c-0.4,1.2-0.7,2.4-0.9,3.7c0,0.1,0,0.2,0,0.2c0.1,1.3,1.2,2.3,2.5,2.2c2.5,0,4.4-2.5,5.4-4.6
		l0.1,0.1c-0.2,0.7-0.3,1.5-0.3,2.3c0,0,0,0.1,0,0.1c0,1.1,0.9,2.1,2,2.2c2.4,0,3.8-2.6,4.6-4.5l-0.6-0.2
		C107.4,17.3,106.5,19.5,105.7,19.5z" />
        <path class="st0" d="M125,17c-0.4,0.7-1.5,2.6-2.5,2.6c0,0,0,0,0,0c-0.5-0.1-0.8-0.6-0.7-1.1c0.1-1.2,0.4-2.4,0.8-3.6l1.4-5.3h2.9
		l0.3-1.2h-2.9l1.3-4.8h-3.2l-1.6,4.8h-2.3h-0.2h-2.7l1.3-4.9h-3.1l-1.6,4.8h-2.4l-0.3,1.2h2.4l-1.3,4.8c-0.5,1.5-0.8,3.1-0.9,4.6
		c0,0,0,0.1,0,0.1c0,1.3,1.2,2.4,2.5,2.3c2.4,0,4-2.7,5-4.6l-0.6-0.3l-0.2,0.4c-0.4,0.7-1.5,2.6-2.5,2.6c0,0,0,0,0,0
		c-0.5-0.1-0.8-0.6-0.7-1.1c0.1-1.2,0.4-2.4,0.8-3.6l1.4-5.3h2.8h0.1h2.3l-1.3,4.8c-0.5,1.5-0.8,3.1-0.9,4.6c0,0,0,0.1,0,0.1
		c0,1.3,1.2,2.4,2.5,2.3c2.4,0,4-2.7,5-4.6l-0.6-0.3L125,17z" />
        <path class="st0" d="M130.4,15.7c2.7-0.7,8.1-1.9,8.1-5.3c0-1.9-2.2-2.6-3.8-2.6c-4.8,0-8.2,4.5-8.2,9.1c0,2.8,1.6,4.6,4.4,4.6
		c2.6,0,4.6-1.9,6-4l-0.7-0.6c-1,1.2-2.4,2.9-4.1,2.9c-0.1,0-0.1,0-0.2,0c-1.1-0.1-1.9-1.2-1.8-2.3
		C130.3,16.8,130.3,16.2,130.4,15.7z M134.5,8.7c0,0,0.1,0,0.1,0c0.6,0.1,1.1,0.6,1,1.3c0,2.4-2.9,4.1-4.9,4.7
		C131.1,13,132.2,8.7,134.5,8.7z" />
        <path class="st0" d="M147.4,10.4c0.7,0,0.7,1.3,2,1.3c0.1,0,0.1,0,0.2,0c1,0,1.7-0.9,1.7-1.8c0-0.1,0-0.2,0-0.3
		c-0.1-1.1-1.1-1.9-2.2-1.8c-2.4,0-3.7,2.9-4.6,4.8l-0.1-0.1c0.1-0.8,0.2-1.7,0.3-2.6c0-0.1,0-0.1,0-0.2c0-1.1-0.8-1.9-1.9-1.9
		c-2.4,0-3.8,2.6-4.7,4.5l0.7,0.3c0.3-0.7,1.1-2.7,2-2.7c0.4,0,0.5,0.4,0.5,0.8c-0.2,2.2-0.6,4.4-1.3,6.6l-1.2,3.8h3.5l1.2-3.3
		C143.8,16.4,145.7,10.4,147.4,10.4z" />
        <path class="st0" d="M164.6,19.4c-0.6,0-0.6-0.7-0.6-1.1c0.1-0.9,0.3-1.8,0.6-2.7l4.3-15.2l-4.8,0.3c-0.6-0.3-1.3-0.4-1.9-0.4
		c-4.4,0-6.2,4.3-7.1,7.8h-2.6l-0.3,1.2h2.6l-2.7,11.8c-0.4,1.8-0.9,3.6-1.3,5.4c-0.1,0.5-0.3,1.5-1.1,1.5c0,0,0,0,0,0
		c-0.4-0.1-0.7-0.6-0.6-1c0,0,0,0,0,0c0-0.7-0.6-1.3-1.3-1.4c-0.8,0-1.4,0.7-1.5,1.5c0,1.5,1.4,2.1,2.7,2.1c3.5,0,5-3.1,5.9-6
		l3.4-13.9h2.9l0.3-1.2h-2.9c0.3-1.7,0.7-3.3,1.2-4.9c0.2-0.6,0.6-1.6,1.5-1.6c1.2,0,0.8,2.1,2.6,2.1c0.2,0,0.4-0.1,0.6-0.1
		c-0.1,0.7-0.3,1.3-0.5,1.9l-2.8,10.3c-0.3,1-0.5,2.1-0.6,3.2c0,0.1,0,0.3,0,0.4c0.1,1.2,1.1,2,2.2,2c2.5,0,4-2.9,4.8-5l-0.7-0.2
		C166.5,17,165.5,19.4,164.6,19.4z" />
        <path class="st0"
          d="M174.8,0c-1.1,0-2,0.9-2,2h0c0,0,0,0,0,0c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2C176.8,0.9,175.9,0,174.8,0z" />
        <path class="st0" d="M173.5,19.4c-0.4,0-0.7-0.4-0.7-0.8c0.1-0.6,0.2-1.2,0.5-1.8l1.2-3.7c0.4-1,0.7-2.1,0.8-3.3c0-0.1,0-0.1,0-0.2
		c-0.1-1.1-1.1-2-2.2-1.9c-2.4,0-4,2.4-5.1,4.2l0.6,0.3c0.4-0.7,1.6-2.7,2.5-2.7c0,0,0,0,0.1,0c0.4,0,0.6,0.4,0.6,0.8
		c-0.2,1.1-0.6,2.2-1,3.2l-0.7,2c-0.5,1.1-0.7,2.3-0.8,3.5c0,0.1,0,0.2,0,0.3c0,1.2,1,2.1,2.2,2c2.4,0,4.1-2.6,5.1-4.5l-0.6-0.3
		C175.5,17.4,174.6,19.4,173.5,19.4z" />
        <path class="st0" d="M181.5,15.7c2.7-0.7,8.1-1.9,8.1-5.3c0-1.9-2.2-2.6-3.8-2.6c-4.8,0-8.2,4.5-8.2,9.1c0,2.8,1.6,4.6,4.4,4.6
		c2.6,0,4.6-1.9,6-4l-0.7-0.6c-1,1.2-2.4,2.9-4.1,2.9c-0.1,0-0.1,0-0.2,0c-1.1-0.1-1.9-1.2-1.8-2.3
		C181.3,16.8,181.3,16.2,181.5,15.7z M185.5,8.7c0,0,0.1,0,0.1,0c0.6,0.1,1.1,0.6,1,1.3h0c0,2.4-2.9,4.1-4.9,4.7
		C182.1,13,183.3,8.7,185.5,8.7z" />
        <path class="st0" d="M196.3,7.7c-2.4,0-4.9,1.3-4.9,4c0,1.7,1.5,3.1,2.6,4.3c0.7,0.6,1.2,1.4,1.2,2.4c0,1-0.8,1.9-1.9,1.9
		c-0.6,0.1-1.2-0.4-1.3-1v-0.5v-0.5c0,0,0-0.1,0-0.1c0-0.7-0.7-1.3-1.4-1.3c-0.1,0-0.1,0-0.2,0c-0.7,0.1-1.3,0.7-1.2,1.4
		c0,2.2,2.4,3,4.2,3c2.4,0,5.1-1.6,5.1-4.2c0-1.8-1-3-2.2-4.3c-0.7-0.7-1.7-1.5-1.7-2.7c0-0.1,0-0.2,0-0.3c0.1-0.8,0.9-1.3,1.7-1.2
		c0.1,0,0.2,0,0.4,0c0.5,0.1,0.8,0.6,0.7,1.1c0,0,0,0,0,0.1c-0.1,0.8,0.5,1.5,1.3,1.5c0,0,0,0,0.1,0c0.7,0,1.2-0.6,1.2-1.3
		C200,8.3,197.8,7.7,196.3,7.7z" />
      </g>
    </svg>

    <div class="fb-info">futurebutterflies.com</div>
  </a>
  <div class="borboletas" *ngIf="hovered">
    <div class="borboleta-1">
      <div class="borboleta-oval-squish">
        <div class="borboleta-oval">
          <div class="borboleta-radial">
            <div class="borboleta-gfx">
              <div class="borboleta-anim"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="borboleta-2">
      <div class="borboleta-oval-squish">
        <div class="borboleta-oval">
          <div class="borboleta-radial">
            <div class="borboleta-gfx">
              <div class="borboleta-anim"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
